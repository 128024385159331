import React from 'react'

export const ContentEn = () => {
  return (
      <div>
        <h2>Personal Data Clause</h2>
        <p>I consent to the processing of my personal data contained in the registration form (in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation); hereinafter “GDPR”) by Smartlope GmbH, Wittstocker Straße 8, 10553 Berlin (hereinafter: “Personal Data Controller”).</p>
        <br />
        <p>By subscribing to the newsletter, I consent to the Controller processing the following data: email address, IP address.</p>
        <br />
        <p>I agree that the Personal Data Controller will continue to process my personal data provided in the registration form solely for the purpose and use of Smartlope services available on the website <a href="https://www.smartlope.com">www.smartlope.com</a>.</p>
        <br />
        <p>I declare that I have been informed about my right to request from the Personal Data Controller easy access to my personal data, its correction, deletion or restrictions on its processing as well as the right to object to its processing and the right to have my data moved to another Controller.</p>
        <p>I declare that I have been informed of my right to object at any time, free of charge, to the usage of my personal data for direct marketing purposes.</p>
        <br />
        <p>I declare that I have been informed that I can easily withdraw my consent to the processing of my personal data by the Personal Data Controller at any time, and that the withdrawal of my consent will not affect the lawfulness of the data processing that was carried out before my objection.</p>
        <br />
        <p>I can exercise my rights by sending email to the the Controller to the following address: <a href="mailto:privacy@smartlope.com">privacy@smartlope.com</a>.</p>
        <br />
            <p>I declare that I have been informed that I have the right to lodge a complaint with the competent data protection authority, i.e. the Berlin Representative for Data Protection and Freedom of Information, tel.: +49 (0)30 13889-0, fax: +49 (0)30 2155050, email: <a href="mailto:mailbox@datenschutz-berlin.de">mailbox@datenschutz-berlin.de.</a></p>
      </div>
  )
}
