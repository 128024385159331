import React from 'react'
import {translate, translateHTML} from "../../../translation/translate"
import { WithRewardInfo } from "./WithRewardInfo"

import './rewards.css'

export const Rewards = ({ locale, setLocale, referralCount }) =>  (
  <section className="rewards">
    <WithRewardInfo filled={referralCount > 0}>
      <div className="reward">
        <h4>{translate('signupSuccessPage.rewards.1Referral.title')}</h4>
        <p>{translate( `signupSuccessPage.rewards.1Referral.description${referralCount > 0 ? 'Filled': ''}`)}</p>
      </div>
    </WithRewardInfo>
    <WithRewardInfo filled={referralCount >= 3}>
      <div className="reward">
        <h4>{translate('signupSuccessPage.rewards.3Referrals.title')}</h4>
        <p>{translate('signupSuccessPage.rewards.3Referrals.description')}</p>
      </div>
    </WithRewardInfo>
    <WithRewardInfo filled={referralCount >= 7}>
      <div className="reward">
        <h4>{translate('signupSuccessPage.rewards.7Referrals.title')}</h4>
        <p>{translate('signupSuccessPage.rewards.7Referrals.description')}</p>
      </div>
    </WithRewardInfo>
    <WithRewardInfo filled={referralCount >= 15} lastOne={true}>
      <div className="reward">
        <h4>{translate('signupSuccessPage.rewards.15Referrals.title')}</h4>
        <p dangerouslySetInnerHTML={{ __html: translate("signupSuccessPage.rewards.15Referrals.description")}} />
      </div>
    </WithRewardInfo>
  </section>
)

