import React from 'react'
import SVGLogo from './logo.svg'

export const Logo = ({
  size = { width: 100, height: 20 },
  onClick,
 color =  '#3f464c'
}) => {
  return (
      <img
          onClick={onClick}
          width={size.width}
          height={size.height}
          src={SVGLogo}
          alt="logo"
      />
  )
}
