import React from 'react'
import { translate } from "../../translation/translate"

import './already_registered.css'

export const AlreadyRegisteredPage = ({ locale, setLocale }) => (
  <section className="alreadyRegistered">
      <h3>{translate('alreadyRegistered.headline')}</h3>
      <p className="black">{translate('alreadyRegistered.description')}</p>
      <small>{translate('alreadyRegistered.small')}</small>
  </section>
)

