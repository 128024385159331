import React from 'react'
import {goToPage} from "../../routing/routing"
import {PAGE} from "../../routing/page"

export const ContentDe = () => {

  return (
      <div>
      <h2>Bestimmungen zum Empfehlungsprogramm – Smartlope</h2>
        <h3>§ 1. Präambel</h3>
        <p>1.	In den vorliegenden Bestimmungen sind die Bedingungen zur Teilnahme an dem Empfehlungsprogramm von Smartlope („Programm“) enthalten, einschließlich der Anforderungen, die erfüllt werden müssen, um die im Programm vorgesehenen Prämien zu erhalten.</p>
        <p>2.	Die Angabe der E-Mail-Adresse im Newsletter, der auf der Seite https://join.smartlope.com/ verfügbar ist, und die Übersendung der E-Mail-Adresse über das Formular ist gleichbedeutend mit der Annahme dieser Bestimmungen und der Zusätzlichen Bestimmungen, die im Online-Dienst abgerufen werden können.</p>
        <p>3.	Sowohl die vorliegenden Bestimmungen als auch der Umfang des Dienstes können Änderungen und Modifizierungen unterliegen, die insbesondere auf Änderungen der zur Verfügung gestellten Funktionen und Änderungen der rechtlichen Vorschriften zurückzuführen sind. Dem Nutzer werden jegliche Modifizierungen des Inhalts der Bestimmungen über das Nutzerkonto sowie an die angegebene E-Mail-Adresse mitgeteilt. Die weitere Nutzung des Dienstes bedeutet die Annahme der Änderungen der Bestimmungen.</p>
        <p>4.	Der Online-Dienst wird von der Gesellschaft Smartlope GmbH, Wittstockerstr. 8, 10553 Berlin, Amtsgericht Berlin-Charlottenburg HRB 216801 B, geführt und verwaltet. Der Administrator stellt den Nutzern die folgende E-Mail-Adresse zur Kontaktaufnahme zur Verfügung: contact@smartlope.com.</p>
          <p>5.	Der Online-Dienst steht Personen ab dem 18. Lebensjahr zur Verfügung.</p>
        <p>6.	Die Nutzung des Dienstes ist kostenlos, ausgenommen davon sind die Funktionen der zahlungspflichtigen Dienste. Der Administrator kann beschließen, die Zahlungspflicht für die Nutzung des Dienstes oder zusätzlicher zahlungspflichtiger Funktionen einzuführen. In einem solchen Fall ist der Nutzer berechtigt, das Nutzerkonto zu löschen, ohne irgendwelche Kosten tragen zu müssen.</p>
        <br />
        <h3>§ 2. Begriffsbestimmungen</h3>

        <p>1.	Administrator / Smartlope – ist die Gesellschaft Smartlope GmbH, Wittstockerstr. 8, 10553 Berlin, Amtsgericht Berlin-Charlottenburg HRB 216801 B.</p>
        <p>2.	Mobile Applikation – ist die Software zur Installierung und Ausführung auf tragbaren Geräten, durch die die Nutzung des Dienstes ermöglicht wird.</p>
        <p>3.	Daten – sind Inhalte, insbesondere Informationen, Bilder und andere Multimedia-Inhalte, ebenso Einträge und Nachrichten, die der Nutzer freiwillig und selbstständig im Dienst veröffentlicht, darunter personenbezogene Daten.</p>
        <p>4.	Personenbezogene Daten – sind jegliche Informationen über den Nutzer, die personenbezogene Daten im Sinne des Art. 4 Abs. 1 der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung) mit Änderungen darstellen, die in dem Umfang und zur Umsetzung der in der Datenschutzerklärung erklärten Zwecke verarbeitet werden, insbesondere Daten, die der Nutzer freiwillig und selbstständig im Anmeldeformular bei der Anmeldung im Online-Dienst unter der Seite https://smartlope.pl/register oder in der mobilen Applikation und während der Nutzung des Dienstes angegeben hat.</p>
        <p>5.	Eigentümer – ist die Person, die über den Ort verfügt (Eigentümer) und das Ereignis bzw. die Veranstaltung für die Personen organisiert, die den Dienst von Smartlope Business in Anspruch nehmen.</p>
        <p>6.	Ort – ist ein in Wirklichkeit existierendes Objekt oder ein Standort mit einer bestimmten Adresse und/oder geografischen Koordinaten, für das das Profil von Smartlope oder von dem Besitzer des Ortes über den Dienst von Smartlope Business angelegt und geführt wird.</p>
        <p>7.	Zusätzliche Bestimmungen – sind Dokumente, die ausgewählte Situationen im Zusammenhang mit der Nutzung des Dienstes spezifisch regeln und einen integralen Bestandteil der vorliegenden Bestimmungen darstellen; die Zusätzlichen Bestimmungen stellen dar:</p>
        <p>7.1.	<a id="NL_privacyPrivacy_clicked" onClick={() => goToPage(PAGE.PRIVACY_POLICY)}><b>die Datenschutzerklärung;</b></a></p>
        <p>7.2.	<a id="NL_privacyPrivacy_clicked" onClick={() => goToPage(PAGE.PERSONAL_DATA_CLAUSE)}><b>die DSGVO-Bestimmungen</b></a></p>
        <p>8.	Veranstaltung – ist ein organisiertes Treffen einer Gruppe von Personen, darunter ausschließlich von Nutzern, bei dem die Teilnehmer gemeinsam eine bestimmte Tätigkeit ausführen (z. B. Workshops).</p>
        <p>9.	Bestimmungen  – bedeuten das vorliegende Dokument.</p>
        <p>10.	(Online-)Dienst – ist der Online-Dienst von Smartlope, der unter der Adresse  https://smartlope.com verfügbar ist und zur Kontaktaufnahme und zur Organisation von Treffen zwischen den Nutzern und der Nutzung von Angeboten der Veranstaltungsorte dient.</p>
        <p>11.	Dienstleistungen – sind Dienstleistungen, die der Administrator auf elektronischem Wege, d.h. über den Dienst, erbringt.</p>
        <p>12.	Zahlungspflichtige Dienste – Dienste, für die der Nutzer eine Gebühr entrichten muss; einen Teil der zahlungspflichtigen Dienste kann der Nutzer freischalten, indem er ein entsprechendes Nutzerlevel erreicht. Die detaillierten Grundsätze zur Vornahme von Zahlungen sind in den Bestimmungen zu Zahlungen festgelegt.</p>
        <p>13.	Nutzer – ist eine natürliche Person, die den Dienst mithilfe des Nutzerkontos in Anspruch nimmt oder sich im Online-Dienst anmeldet.</p>
          <p>14.	Ereignis – ist ein organisiertes Treffen einer Gruppe von Personen, darunter ausschließlich von Nutzern, bei dem die Teilnehmer gemeinsam eine bestimmte Tätigkeit ausführen; Ereignisse können unter Nutzung der Infrastruktur des Ortes oder außerhalb des Ortes organisiert werden.</p>
        <br />
        <h3>§ 3. Bedingungen zur Teilnahme am Programm</h3>
        <p>1.	An dem Programm können natürliche Personen teilnehmen, die alle folgenden Bedingungen erfüllen:</p>
        <p>1.1.	sie haben das 18. Lebensjahr vollendet und besitzen die Fähigkeit, Rechtshandlungen vorzunehmen,</p>
        <p>1.2.	sie haben eine E-Mail-Adresse auf der Seite https://join.smartlope.com/ angegeben und sie über das Formular übersandt, wodurch sie die vorliegenden Bestimmungen akzeptieren;</p>
        <p>1.3.	sie handeln in Einklang mit den vorliegenden Bestimmungen und den allgemein geltenden Rechtsvorschriften.</p>
        <p>2.	Nach der Übersendung des Anmeldeformulars erhält der Nutzer einen einmaligen Link („Referral ID“), der ihm an die von ihm angegebene E-Mail-Adresse zugesandt wird. Der Nutzer hat die Möglichkeit, seine Bekannten über die Referral ID zur Anmeldung in dem Online-Dienst einzuladen.</p>
        <p>3.	Die Personen, die der Nutzer einlädt, sollten den Newsletter abonnieren und ihre E-Mail-Adresse über den einmaligen Link („Referral ID“) übersenden, den ihnen der Nutzer, der sie einlädt, zugesandt hat.</p>
        <p>4.	Jede Person darf lediglich ein einmaliges Konto im Online-Dienst besitzen. Es ist untersagt, Konten von mehreren verschiedenen E-Mail-Adressen aus anzulegen.</p>
        <br />
        <h3>§ 4. Prämien und Grundsätze ihrer Zuerkennung</h3>
        <p>1.	Sind alle in § 3 oben angegebenen Bedingungen erfüllt, hat der Nutzer die Chance, Prämien zu erhalten, unter Vorbehalt der nachfolgenden Punkte.</p>
        <p>2.	In Fällen, in denen der Nutzer eine weitere Person einlädt, die den Newsletter erfolgreich abonniert (über den vom Nutzer übersandten einmaligen Link („Referral ID“), stehen dem Nutzer die folgenden Prämien zu:</p>
        <p>2.1.	für 1 neu gewonnenen Nutzer – gutes Karma &#9786;</p>
        <p>2.2. für 3 neu gewonnene Nutzer – Smartlope-Coupon im Wert von 10 EUR, der innerhalb von 6 Monaten ab dem Datum der Inbetriebnahme des Smartlope-Dienstes zu verwenden ist</p>
        <p>2.3.	für 7 neu gewonnene Nutzer – Extra Smartlope-Coupon im Wert von 20 EUR, der innerhalb von 6 Monaten ab dem Datum der Inbetriebnahme des Smartlope-Dienstes zu verwenden ist,</p>
        <p>2.4.	für 15 neu gewonnene Nutzer – 5 extra Smartlope-Coupons im Wert von jeweils 20 EUR (Gesamtwert der Coupons: 100 EUR), die innerhalb von 6 Monaten ab dem Datum der Inbetriebnahme des Smartlope-Dienstes zu verwenden sind.</p>
        <br />
        <h3>§ 5. Schlussbestimmungen</h3>
        <p>1.	In Angelegenheiten, die nicht durch die vorliegenden Bestimmungen geregelt sind, gelten die Vorschriften des allgemein geltenden Rechts sowie die übrigen internen Dokumente, die im Smartlope-Dienst zur Verfügung stehen.</p>
        <p>2.	In Fällen, in denen der Nutzer gegen die vorliegenden Bestimmungen verstoßen hat, darunter insbesondere zur Erlangung der Prämien:</p>
          <p>2.1.	mehrere E-Mail-Adressen verwendet hat;</p>
        <p>2.2.	zusätzliche E-Mail-Adressen angelegt hat;</p>
        <p>wird er von der Teilnahme am Programm ausgeschlossen und ist nicht zum Erhalt der Prämie berechtigt.</p>
        <p>3.	Die Bestimmungen können Änderungen und Modifizierungen unterliegen.</p>
        <p>4.	Die Inhalte der vorliegenden Bestimmungen können auf der Seite https://join.smartlope.com/ abgerufen werden.</p>
      </div>
  )
}
