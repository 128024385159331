import React from 'react'
import { goToPage } from "../../routing/routing"
import { PAGE } from "../../routing/page"
import { translate } from "../../translation/translate"
import Facebook from './facebook.svg'
import Instagram from './ig.svg'
import Berlin from './berlin.svg';

import './footer.css'

export const Footer = ({ referralId, referralLink, isSplashDesktopPage, isDesktop }) =>  (
  <footer className={isSplashDesktopPage ? 'desktopSplash' : ''}>
    {!isSplashDesktopPage && <img className="background" alt="Berlin" src={Berlin} width="100%" />}
    <p className="socialMediaLinksParagraph">{translate("footer.followUs")}</p>
    <nav className="socialMediaLinks">
      <a id="NL_footer_facebook" href="https://www.facebook.com/SmartlopeAdventure" target="_blank" rel="noopener" aria-label="Facebook">
        <img alt="Facebook" src={Facebook} width="32" height="32" />
      </a>
      <a id="NL_footer_instagram" href="https://www.instagram.com/smartlope.adventure/" target="_blank" rel="noopener" aria-label="Instagram">
        <img alt="Instagram" src={Instagram} width="32" height="32" />
      </a>
    </nav>
    <div className="otherLinks">
      <nav>
        <h6>{translate("footer.community")}</h6>
        <a id="NL_footer_blog" href="https://blog.smartlope.com">{translate("footer.blog")}</a>
        <a id="NL_footer_join_smartlope" href="https://join.smartlope.com">{translate("footer.join")}</a>
        <a id="NL_footer_feedback" href="mailto:contact@smartlope.com">{translate("footer.feedback")}</a>
      </nav>
      <nav>
        <h6>{translate("footer.about")}</h6>
        <a id="NL_footer_our story" href="https://blog.smartlope.com/what-is-smartlope/">{translate("footer.ourStory")}</a>
        <a id="NL_footer_become_partner" href="https://partner.smartlope.com">{translate("footer.partner")}</a>
        <a id="NL_footer_press" href="mailto:contact@smartlope.com">{translate("footer.press")}</a>
        <a id="NL_footer_contact" href="mailto:contact@smartlope.com">{translate("footer.contact")}</a>
      </nav>
    </div>
    <nav className="legalLinks">
      <p>&copy; 2021 Smartlope GmbH. {translate("footer.allRightsReserved")}</p>
      <a id="NL_imprint_clicked" onClick={() => goToPage(PAGE.IMPRINT)}>{translate("footer.imprint")}</a> &nbsp;<small>&bull;</small>
      &nbsp;&nbsp;
      <a id="NL_privacy_clicked" onClick={() => goToPage(PAGE.PRIVACY_POLICY)}>{translate("footer.privacyPolicy")}</a> &nbsp;<small>&bull;</small>
      &nbsp;&nbsp;
      <a id="NL_privacy" onClick={() => goToPage(PAGE.PERSONAL_DATA_CLAUSE)}>{translate("footer.newsletterClause")}</a> &nbsp;<small>&bull;</small>
      &nbsp;&nbsp;
      <a id="NL_terms_clicked" onClick={() => goToPage(PAGE.TERMS_AND_CONDITIONS)}>{translate("footer.termsAndConditions")}</a>
    </nav>
  </footer>
)

