import React from 'react'

export const ContentEn = () => {

  return (
      <div>
            <h3>Smartlope is a service provided by:</h3>
        <br />
        Smartlope GmbH<br />
        Wittstockerstr. 8<br />
        10553 Berlin
        <br />
        <br />
        Email: <a href="mailto:contact@smartlope.com">contact@smartlope.com</a><br />
        County Court of Berlin-Charlottenburg HRB 216801 B<br />
        VAT Registration No. pursuant to § 27 a of the German VAT Code: DE333177248<br />
        Managing Director: Grzegorz Forysiak
        <br />
        <br />
        Online Dispute Resolution according to Art. 14 Abs. 1 ODR Regulation: The European Commission provides an online dispute resolution (ODR) platform accessible under <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.<br />
        Smartlope is not willing or obligated to participate in dispute resolution proceedings before a consumer dispute resolution body (§ 36 (1) No. 1 of the German Act on Dispute Resolution in Consumer Matters (VSBG)).
      </div>
  )
}
