import React from 'react'
import { translate } from "../../../../translation/translate"

import "./city_is_your_playground.css"

export const CityIsYourPlayground = ({ isSplashDesktopPage }) => (
    <div className={`cityIsYourPlayground contentSection${isSplashDesktopPage ? ' withExtraBackground': ''}`}>
      <div className="description">
        <h3>{translate("newsletterFormPage.cityIsYourPlayground.headline")}</h3>
        <p>{translate("newsletterFormPage.cityIsYourPlayground.description")}</p>
      </div>
      <div className="tags">
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.wineTasting")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.art")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.movies")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.food")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.standUpComedy")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.whiskeyTasting")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.beerTasting")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.exhibitions")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.speedDating")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.boardGames")}</div>
        <div className="tag">{translate("newsletterFormPage.cityIsYourPlayground.tags.andMuchMore")}</div>
      </div>
      <div className="buttonsGroup">
        <a
          id="NL_Signup_third_CTA"
          className="button"
          type="button"
          href="#newsletterForm">
          {translate('newsletterFormPage.form.cta')}
        </a>
      </div>
    </div>
)
