export const PAGE = {
  SPLASH: 'splash',
  SIGNUP_SUCCESS: 'signupSuccess',
  PRIVACY_POLICY: 'privacyPolicy',
  ALREADY_REGISTERED: 'alreadyRegistered',
  PERSONAL_DATA_CLAUSE: 'personalDataClause',
  REFERRAL_SUMMARY: 'referralSummary',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  IMPRINT: 'imprint',
}
