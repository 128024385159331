import React from 'react'

import './document.css'

export const Document = ({ children }) => {

  return (
      <div className="document">
        {children}
      </div>
  )
}
