import React from "react"
import { translate } from "../../translation/translate"
import { goToPage } from "../../routing/routing"
import { PAGE } from "../../routing/page"

export const GoBack = () => {

  return (
      <div>
        <a onClick={() => goToPage(PAGE.SPLASH)}>« {translate("goBack")}</a>
        <br />
        <br />
      </div>
  )
}
