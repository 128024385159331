import React from 'react'

export const ContentDe = () => {

  return (
      <div>
        <h2>Klausel über personenbezogene Daten</h2>
        <p>Ich erkläre mich damit einverstanden, dass meine personenbezogenen Daten, die im Anmeldeformular enthalten sind, verarbeitet werden (gemäß der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung); im Folgenden: „DSGVO“), und zwar durch die Smartlope GmbH, Wittstocker Straße 8, 10553 Berlin (im Folgenden: „Verantwortlicher“).</p>
        <br />
        <p>Indem ich den Newsletter abonniere, erkläre ich mich damit einverstanden, dass der Verantwortliche die folgenden Daten verarbeitet: E-Mail-Adresse, IP-Adresse.</p>
<br />
        <p>Ich erkläre mich damit einverstanden, dass der Verantwortliche meine personenbezogenen Daten, die ich im Anmeldeformular angegeben habe, ausschließlich für den Bedarf und zur Verwendung des Dienstes von Smartlope verarbeitet, der unter der Seite <a href="https://www.smartlope.com">www.smartlope.com</a> verfügbar ist.</p>
        <br />
        <p>Ich erkläre, dass ich über das mir zustehende Recht unterrichtet wurde, vom Verantwortlichen zu verlangen, mir Zugang zu meinen personenbezogenen Daten zu beschaffen, sie zu berichtigen, zu löschen oder die Verarbeitung einzuschränken. Ich wurde auch über mein Widerspruchsrecht gegen die Verarbeitung und mein Recht auf Datenübertragbarkeit belehrt.</p>
        <p>Ich erkläre, dass ich über mein Recht belehrt wurde, jederzeit und gebührenfrei Widerspruch gegen die Verarbeitung meiner personenbezogenen Daten zu Direktmarketingzwecken einzulegen.</p>
        <br />
        <p>Ich erkläre, dass ich über die Möglichkeit belehrt wurde, meine Zustimmung zur Verarbeitung meiner personenbezogenen Daten durch den Verantwortlichen jederzeit zurückzuziehen und dass dies sich nicht auf die Rechtmäßigkeit der Verarbeitung auswirkt, die bis dahin auf Grundlage meiner Zustimmung erfolgt ist.</p>
        <br />
        <p>Ich kann meine Rechte per E-Mail an den Verantwortlichen unter der Adresse: <a href="mailto:privacy@smartlope.com">privacy@smartlope.com</a> geltend machen.</p>
        <br />
        <p>Ich erkläre, dass ich über mein Recht belehrt wurde, Beschwerde bei der zuständigen Datenschutzbehörde einzulegen, d.h. beim Bundesbeauftragten für den Datenschutz und die Informationsfreiheit, Tel.: +49 (0)30 13889-0, Fax: +49 (0)30 2155050, E-Mail: <a href="mailto:mailbox@datenschutz-berlin.de">mailbox@datenschutz-berlin.de</a>.</p>
      </div>
  )
}
