import React from 'react'

export const ContentEn = () => {

  return (
      <div>
          <h2>PRIVACY POLICY</h2>
        <h3>1. General information</h3>
        <p>The following Privacy Policy sets out the principles informing Users of Smartlope (hereinafter: “the Service”, “the Portal”) about the aspects of obtaining, processing, and securing personal data in the Service in accordance with the requirements of the general law, in particular those set out in Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation; hereinafter “GDPR”).</p>
        <p>The Controller shall pay due attention to the protection of personal data and the confidentiality of the information entrusted to it. Personal data is defined as information about an identified or identifiable natural person. The Controller shall process the personal data for the purposes set out in this Policy and in the Terms of Service. Depending on the purpose, the ways of collecting this data, and the legal basis for its processing, use, disclosure and retention periods may vary. The only personal data collected is what is voluntarily provided by visitors to and users of the Service.</p>
        <p>The Controller for your personal data (Art. 4(7) GDPR) is Smartlope GmbH, Wittstocker Straße 8, 10553 Berlin, email: privacy@smartlope.com, (hereinafter: “the Controller”).</p><br />
            <p>The Controller follows the principles regarding the confidentiality of data collected while users are using Smartlope (hereinafter: “the Service”).</p>
      <ol>
            <li>The Controller shall collect data from people who have registered with the Service using the registration form available at https://join.smartlope.com.</li>
            <li>By using the Service, the User accepts the terms and conditions of this Privacy Policy.</li>
            <li>The personal data of Website users provided during the registration process and in their profiles shall be processed in order to properly implement the services provided by the Service and for the purposes indicated in the relevant forms of the Service. The legal basis for this data processing are Art. 6(1)(b) and (f) GDPR.</li>
            <li>Users’ personal data shall be stored only for the necessary time (e.g. the period of the relationship between the user and the Service) unless the User objects earlier to the processing of their personal data. Providing personal data is voluntary but necessary for the services offered by the Portal, including sending marketing information.</li>
        <li>Only if necessary, the Service may transfer personal data to the following:<br />
        a.	external organisations providing applications/functionality or data processing or IT services (e.g. information technology and cloud software providers, as well as identity management, web hosting and website management, and data analysis, backup, security, etc. service providers);<br />
        b.	third-party organisations that help us by providing goods or information, or rendering services;<br />
        c.	law enforcement agencies, regulatory bodies, and other governmental bodies or third parties if the requirement to transfer the data results from a legal obligation (Art. 6(1)(c) GDPR).<br />
              The third-party organisations we use for our business transactions are acting exclusively on our behalf and are contractually obligated to comply with the data protection legislation in accordance with Art. 28 GDPR.
        </li>
            <li>A person whose personal data is being stored by the Controller is entitled to declare their rights in writing or electronically using the contact form made available by the Controller on the webpage: https://join.smartlope.com. A response to a request shall be given without undue delay, at the latest within one month of receiving the request.</li>
        <li>Users are entitled to obtain confirmation from the Controller that the Controller is processing their personal data (Art. 15 GDPR) and, if this is the case, the entity is entitled to obtain access to the data and information about the purpose for its processing and categorisation, any recipients to whom the personal data can be or has been transferred, the period during which the data has been processed, and the rights to which they are entitled, including:<br />
        a.	the right to request the rectification (Art. 16 GDPR), transfer, obtaining of a copy (Art. 20 GDPR), deletion (Art. 17 GDPR), or restriction of processing (Art. 18 GDPR) of one’s personal data;<br />
        b.	the right to withdraw consent (Art. 7(3) GDPR);<br />
        c.	the right to object to the processing of the data (Art. 21 GDPR);<br />
        d.	the right to lodge a complaint with a supervisory authority (Art. 77 GDPR);<br />
              as well as information on automated decision-making, including profiling, relevant information about the logic involved as well as the significance and the envisaged consequences of such processing for the data subject.</li>
      </ol>
        <br />
        <h3>2.	Cookies and profiling</h3>
        <ol>
        <li>The Controller may, with the permission of the Website user, store certain information on the user’s computer using cookies.</li>
        <li>Cookies are not used to obtain any information about Website users or track their navigation. Cookies used on the Website do not store any personal data or other information collected from users. They are used, amongst other things, to remember certain user preferences and assist the Controller in making improvements to the Website.</li>
        <li>In many cases, software used to browse the Internet (web browsers) by default allows the storage of cookies on the user’s end device. Users of the Website may change their cookie settings at any time. These settings can be changed in such a way as to block the automatic handling of cookies in the web browser’s settings or to inform the user each time they are stored on the Website user’s device. Detailed information on the possibilities and methods of handling cookies is available in the web browser settings.</li>
        <li>The User may give the consent referred to above using the settings of the software installed on the end device they are using or by configuring the service – i.e. to disable or restrict the use of cookies, the user may change the settings of their Internet browser. However, this could result in the Website not operating correctly or a loss of access to some of its subpages.</li>
        <li>The Controller hereby informs the user that restrictions on the use of cookies may affect some of the functionalities available on the Service’s website.</li></ol>
            <br />
            <br />
            <h3>3.	Use of the Service and risks</h3>
            <ol>
                  <li>Users of the Service are aware that data transmitted on the public telecommunications network between their devices and the Service is not completely secure. The Controller is not able to ensure the full protection and security of such data during communications with the Service but guarantees that appropriate action will be taken to secure all data sent to it electronically, particularly personal data provided by the user via electronic forms.</li>
                  <li>Access to the database is password-protected and files are stored on servers in encrypted files.</li>
                  <li>The Portal may contain links to third-party websites or services. These third-party links may have their own electronic service activities and privacy policies that you agree to follow when you click the link and leave the Website.</li>
                  <li>Changes to the management of the Website<br/>
                        There may be circumstances where the Controller decides to sell or transfer all or some of its business or assets. In this case, users’ personal data may be transferred or made available by the Controller to third parties as part of and in connection to the planned transaction. In such situations, the Controller shall ensure that the third parties have the obligation to provide adequate protection of personal data collected through the Website. Additionally, the Controller shall inform Website users about these circumstances and users shall have the right to request the deletion of their data.</li>
              <li><b>Changes to the Privacy Policy</b><br />
                        This Privacy Policy may change occasionally. Any changes to it shall be published on the Website.</li>
              <li><b>Contact</b><br />
                        Should you have any questions about the processing of your personal data by the Controller, you may contact them by email at privacy@smartlope.com.</li>
            </ol>
      </div>
  )
}
