import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { SplashPage } from "./pages/splash/SplashPage"
import { PAGE } from "./routing/page"
import { SignupSuccessPage } from "./pages/signup_success/SignupSuccessPage"
import { getPersistedLocale, LOCALE, setPersistedLocale } from "./translation/session"
import { CookieBanner } from "./partials/cookie_banner/CookieBanner"
import { isCookieAccepted, setIsCookieAccepted } from "./partials/cookie_banner/persistenceProvider"
import { PersonalDataClause } from "./pages/personal_data_clause/PersonalDataClause"
import { PrivacyPolicy } from "./pages/privacy_policy/PrivacyPolicy"
import { Footer } from "./partials/footer/Footer"
import { Nav } from "./partials/navigation/Nav"
import { ImprintPage } from "./pages/imprint/ImprintPage"
import { Helmet } from 'react-helmet'
import { translate } from "./translation/translate"
import { AlreadyRegisteredPage } from "./pages/alreadyRegistered/AlreadyRegisteredPage"
import { TermsAndConditions } from "./pages/terms_and_conditions/TermsAndConditions"

import './app.css';

const PAGES = {
  [PAGE.SPLASH]: SplashPage,
  [PAGE.SIGNUP_SUCCESS]: SignupSuccessPage,
  [PAGE.PRIVACY_POLICY]: PrivacyPolicy,
  [PAGE.IMPRINT]: ImprintPage,
  [PAGE.PERSONAL_DATA_CLAUSE]: PersonalDataClause,
  [PAGE.ALREADY_REGISTERED]: AlreadyRegisteredPage,
  [PAGE.REFERRAL_SUMMARY]: SignupSuccessPage,
  [PAGE.TERMS_AND_CONDITIONS]: TermsAndConditions
}

export const hashToPageName = () => {
  const pageName = window.location.hash.replace("#", "");

  if (pageName.startsWith(PAGE.SIGNUP_SUCCESS)) {
    return PAGE.SIGNUP_SUCCESS;
  }

  if (pageName.startsWith(PAGE.PRIVACY_POLICY)) {
    return PAGE.PRIVACY_POLICY;
  }

  if (pageName.startsWith(PAGE.PERSONAL_DATA_CLAUSE)) {
    return PAGE.PERSONAL_DATA_CLAUSE;
  }

  if (pageName.startsWith(PAGE.IMPRINT)) {
    return PAGE.IMPRINT;
  }

  if (pageName.startsWith(PAGE.ALREADY_REGISTERED)) {
    return PAGE.ALREADY_REGISTERED;
  }

  if (pageName.startsWith(PAGE.REFERRAL_SUMMARY)) {
    return PAGE.REFERRAL_SUMMARY;
  }

  if (pageName.startsWith(PAGE.TERMS_AND_CONDITIONS)) {
    return PAGE.TERMS_AND_CONDITIONS;
  }

  return PAGE.SPLASH;
}

export const getCurrentActiveHomePage = (referralId, referralLink) => {
  const params = referralId && referralLink ? `?referralId=${referralId}&referralLink=${referralLink}` : "";
  return params
      ? `${PAGE.SIGNUP_SUCCESS}${params}`
      : PAGE.SPLASH;
}

export const App = () => {
  let [ locale, setLocale ] = useState(getPersistedLocale() || LOCALE.en)
  let [ referralId, setReferralId ] = useState()
  let [ subscriptionSummaryKey, setSubscriptionSummaryKey ] = useState()
  let [ referralLink, setReferralLink ] = useState()
  let [ cookieAccepted, setCookieAccepted ] = useState(isCookieAccepted())
  const initialPage = hashToPageName()
  let [ currentPage, setCurrentPage ] = useState(initialPage)

  window.onhashchange = function () {
    const currentPage = hashToPageName()
    setCurrentPage(currentPage)
    dispatchPageView()
  }

  const acceptCookie = () => {
    setCookieAccepted(true)
    setIsCookieAccepted()
  }

  useEffect(() => {
    if (window.location.pathname === "/de/") {
      const hash = window.location.hash
      window.location.href = `${window.location.origin}${hash || ""}`
      setLocale(LOCALE.de)
      setPersistedLocale(LOCALE.de)
    }
    dispatchPageView()
  }, [])

  const Page = PAGES[currentPage]

  const dispatchPageView = () => {
    const data = {
      URL: window.location.href,
      pageType: hashToPageName(),
    }
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.href,
      ...data
    });
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isSplashPage = hashToPageName() === PAGE.SPLASH
  const isSplashDesktopPage = isDesktopOrLaptop && isSplashPage

  return (
      <>
        <Helmet>
          <title>Smartlope | {translate("head.title")}</title>
          <meta name="description" content={translate("head.description")} />
          <meta property="og:title" content={translate("head.title")} />
          <meta property="og:url" content="https://join.smartlope.com/" />
          <meta property="og:locale" content={locale === LOCALE.en ? "en_EN" : "de_DE"} />
        </Helmet>
        <div className={`app white_${isSplashPage}`}>
          <Nav
              isSplashDesktopPage={isSplashDesktopPage}
              referralLink={referralLink} referralId={referralId}
              whiteBackground={isSplashPage}
              setLocale={setLocale}
              locale={locale}
          />
          <div className="page">
            <Page
                isSplashDesktopPage={isSplashDesktopPage}
                setSubscriptionSummaryKey={setSubscriptionSummaryKey}
                setReferralId={setReferralId}
                setReferralLink={setReferralLink}
                locale={locale}
                setLocale={setLocale}
            />
          </div>
          <Footer
            isDesktop={isDesktopOrLaptop}
            isSplashDesktopPage={isSplashDesktopPage}
            referralLink={referralLink}
            referralId={referralId}
          />
          <CookieBanner
            cookieAccepted={cookieAccepted}
            setCookieAccepted={acceptCookie}
          />
        </div>
      </>
  );
}
