import React from 'react'

import './rewardIndicator.css'

export const WithRewardInfo = ({ filled, lastOne, children }) =>  (
    <div className="rewardsIndicatorContainer">
      <div className={`indicator${filled ? ' filled' : ''}${lastOne ? ' lastOne' : ''}`}>
        <span className="circle"><span /></span>
        <span className="line"></span>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
)

