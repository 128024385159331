import React from 'react'
import { Form } from './partials/form/Form'
import { WhatIsSmartlope } from "./partials/what_is_smartlope/WhatIsSmartlope"
import { CityIsYourPlayground } from "./partials/city_is_your_playground/CityIsYourPlayground"

export const SplashPage = ({ locale, setLocale, setReferralId, setReferralLink, isSplashDesktopPage }) =>  (
  <div>
    <Form
      setReferralId={setReferralId}
      setReferralLink={setReferralLink}
      setLocale={setLocale}
      locale={locale}
    />
    <WhatIsSmartlope />
    <CityIsYourPlayground
        isSplashDesktopPage={isSplashDesktopPage}
    />
  </div>
)


