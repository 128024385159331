export const LOCALE = {
  de: 'de',
  en: 'en'
}

export const setPersistedLocale = (locale) => {
  sessionStorage.setItem('locale', locale)
}

export const getPersistedLocale = () => {
  return sessionStorage.getItem('locale') || LOCALE.en;
}
