import React from 'react'
import { translate } from "../../../../translation/translate"

import Celebration from './celebration_white.svg'
import Explore from './explore_white.svg'
import Group from './group_white.svg'

import "./what_is_smartlope.css"

export const WhatIsSmartlope = () => (
  <div className="whatIsSmartlope contentSection">
    <div className="description">
      <h3>{translate("newsletterFormPage.whatIsSmartlope.headline")}</h3>
      <p>{translate("newsletterFormPage.whatIsSmartlope.description")}</p>
    </div>
    <div className="panels">
      <div className="panel">
        <img width="32" height="32" src={Celebration} alt="Its no dating app" />
        <h4>{translate("newsletterFormPage.whatIsSmartlope.panels.notDatingApp.headline")}</h4>
        <p>{translate("newsletterFormPage.whatIsSmartlope.panels.notDatingApp.content")}</p>
      </div>
      <div className="panel">
        <img width="32" height="32" src={Explore} alt="Its no dating app" />
        <h4>{translate("newsletterFormPage.whatIsSmartlope.panels.bestThingsInTown.headline")}</h4>
        <p>{translate("newsletterFormPage.whatIsSmartlope.panels.bestThingsInTown.content")}</p>
      </div>
      <div className="panel">
        <img width="32" height="32" src={Group} alt="Its no dating app" />
        <h4>{translate("newsletterFormPage.whatIsSmartlope.panels.smallGroups.headline")}</h4>
        <p>{translate("newsletterFormPage.whatIsSmartlope.panels.smallGroups.content")}</p>
      </div>
    </div>
    <div className="buttonsGroup">
      <a
          id="NL_Signup_Secondary_CTA"
          className="button"
          type="button"
          href="#newsletterForm">
        {translate('newsletterFormPage.whatIsSmartlope.joinTheParty')}
      </a>
    </div>
  </div>
)
