import React, { useState } from 'react'
import { translate, translateHTML } from "../../../../translation/translate"
import { postData } from "../../../../http_client/httpClient"
import { goToPage } from "../../../../routing/routing"
import { PAGE } from "../../../../routing/page"
import { emailRegexPattern } from "./emailRegexPattern"
import { getPersistedLocale } from "../../../../translation/session"

import './form.css'

export const Form = ({ setReferralId, setReferralLink, subscriptionSummaryKey }) => {
  const [ email, setEmail ] = useState("")
  const [ inProgress, setInProgress ] = useState(false)
  const [ error, setError ] = useState(false)
  const onEmailChange = ({ target: { value }}) => setEmail(value)

  const urlParams = new URLSearchParams(window.location.search);
  const referredBy = urlParams.get('referral-id') || null;

  const onSubmit = () => {
    if (emailRegexPattern.test(email)) {
      setInProgress(true)
      postData("/api/newsletter", {
        email,
        referredBy,
        locale: getPersistedLocale()
      })
      .then((data) => {
        const  { referralId, referralLink, referredBy, subscriptionSummaryLink, subscriptionSummaryKey } = data

        if (!referralId) {
          setInProgress(false)
          setError(true)
        } else {

          if (!subscriptionSummaryKey) {
            return goToPage(PAGE.ALREADY_REGISTERED);
          }

          setError(false)
          setReferralId(referralId)
          setReferralLink(referralLink)
          const targetPage = `${PAGE.SIGNUP_SUCCESS}?referralId=${referralId}&summaryKey=${subscriptionSummaryKey}`
          goToPage(targetPage)
        }
      })
    }
  }

  const onKeyUp = ({ which }) => {
    if (which === 13) {
      onSubmit()
    }
  }

  return (
    <div
      className="form"
      id="newsletterForm">
      <div className="outerFormContainer">
        <section>
          <div className="description">
            <h2>{translate("newsletterFormPage.headline")}</h2>
            <p dangerouslySetInnerHTML={{ __html: translateHTML("newsletterFormPage.subHeadline")}} />
          </div>
          <div className={`formContainer${error ? ' withError': ''}`}>
            <input
              id="NL_Signup_input_field"
              type="email"
              value={email}
              onChange={onEmailChange}
              onKeyUp={onKeyUp}
              placeholder={translate("newsletterFormPage.form.placeholder")}
            />
            <button
              disabled={inProgress}
              id="NL_Signup_Primary_CTA"
              onClick={onSubmit}
              type="button">
              {translate("newsletterFormPage.form.cta")}
            </button>
          </div>
        </section>
      </div>
      <span className="additionalBackground" />
    </div>
  )
}
