import _get from 'lodash.get';
import en from './en.json'
import de from './de.json'
import {getPersistedLocale, LOCALE} from "./session"

const LANGUAGES_MAP = {
  [LOCALE.en]: en,
  [LOCALE.de]: de,
}

export const translate = (key) => {
  return _get(LANGUAGES_MAP[getPersistedLocale()], key)
}

export const translateHTML = (key) => {
  return _get(LANGUAGES_MAP[getPersistedLocale()], key)
}
