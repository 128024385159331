import React, { useEffect, useState } from 'react'
import { translate, translateHTML } from "../../../translation/translate"

import './sharing.css'

export const Sharing = ({ locale, setLocale, referralLink }) => { //FIXME: sorry for that ;)
  const [ copied, setCopied ] = useState(false)

  const copyToClipboard = () => {
    const copyText = document.getElementById("referralLink")
    copyText.select();
    document.execCommand("copy")
    copyText.blur()
    setCopied(true)
    setTimeout(( ) => {
      setCopied(false)
    }, 1000)
  }

  const getReferralMailLink = () => {
    const subject = encodeURIComponent(translate("signupSuccessPage.sharing.email.subject"))
    const body = encodeURIComponent(translate("signupSuccessPage.sharing.email.body").replace('LINK', referralLink))
    return `mailto:?subject=${subject}&body=${body}`
  }

  const getWhatsappLink = () => {
    return `https://wa.me/?text=${encodeURIComponent(translate('signupSuccessPage.sharing.whatsapp.text').replaceAll('LINK', referralLink))}`
  }

  const getFacebookLink = () => {
    const link = encodeURIComponent(referralLink)
    const title = translate("signupSuccessPage.sharing.facebook.title")
    return `https://www.facebook.com/sharer/sharer.php?u=${link}&t=${title}`
  }

  return (
      <section className="sharing">
        <div>
          <h3 className="centered">{translate('signupSuccessPage.sharing.headline')}</h3>
        </div>
        <form>
          <input
              id="referralLink"
              type="text"
              value={referralLink}
          />
          {copied && <span className="copied">{translate("copied")}</span>}
          <a
              id="NL_sharing_started_copy"
              className="button copy"
              onClick={copyToClipboard}>
            <span>{translate('signupSuccessPage.sharing.copy')}</span>
          </a>
        </form>
        <a
            id="NL_sharing_started_email"
            className="button email"
            href={getReferralMailLink()}
            title={translate('signupSuccessPage.sharing.emailInvite')}>
          <span>{translate('signupSuccessPage.sharing.emailInvite')}</span>
        </a>
        <a
            id="NL_sharing_started_whatsapp"
            data-action="share/whatsapp/share"
            className="button whatsapp"
            href={getWhatsappLink()}
            title={translate('signupSuccessPage.sharing.whatsappInvite')}>
          <span>{translate('signupSuccessPage.sharing.whatsappInvite')}</span>
        </a>
        <a
            id="NL_sharing_started_facebook"
            className="button facebook"
            target="_blank"
            href={getFacebookLink()}
            onClick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
            title={translate('signupSuccessPage.sharing.facebookInvite')}>
          <span>{translate('signupSuccessPage.sharing.facebookInvite')}</span>
        </a>
      </section>
  )
}

