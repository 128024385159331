import React, { useState, useEffect } from 'react'
import { translate } from "../../translation/translate"
import { Rewards } from "./rewards/Rewards"
import { WithRewardInfo } from "./rewards/WithRewardInfo"
import { Sharing } from "./sharing/Sharing"
import { getData } from "../../http_client/httpClient"

import './signup_succes.css'

export const SignupSuccessPage = ({ locale, setLocale }) => {
  const [ referralCount, setReferralCount ] = useState(0)
  const [ email, setEmail ] = useState(0)
  const [ numberOfUsersWhichDid25Referrals, setNumberOfUsersWhichDid25Referrals ] = useState(0)
  const [ referralLink, setReferralLink ] = useState(0)
  let summaryKey;

  const getRewardLevel = () => {
    if (referralCount >= 25) return "fourth";
    if (referralCount >= 10) return "third";
    if (referralCount >= 5) return "second";
    if (referralCount > 0) return "first";
    return "none";
  }

  try { summaryKey = (window.location.hash.split("summaryKey=") || [])[1].split("&")[0] } catch (e) {}

  useEffect(() => {

    if (summaryKey) {
      getData(`/api/newsletter/summary?summaryKey=${summaryKey}`)
        .then(({ numberOfUsersWhichDid25Referrals , referralsCount, email, referralLink }) => {
          setReferralCount(referralsCount)
          setNumberOfUsersWhichDid25Referrals(numberOfUsersWhichDid25Referrals)
          setEmail(email)
          setReferralLink(referralLink)
        })
    }
  }, [])

    return (
      <div className="confirmationContainer">
        <div style={{paddingLeft: '25px'}}>
          {email ? (
              <WithRewardInfo filled={true}>
                <div className="confirmation">
                  <div>
                    <h3>{translate('signupSuccessPage.headline')}</h3>
                    <p className="black">{translate('signupSuccessPage.description')}</p>
                    <small className="black">{email}</small>
                  </div>
                </div>
                <h3>{translate(`signupSuccessPage.rewards.headlines.${getRewardLevel()}`)}</h3>
              </WithRewardInfo>
          ): (
              <div className="confirmation">
                <div>
                  <h3>{translate('signupSuccessPage.rewards.mainHeadline')}</h3>
                </div>
              </div>
          )}
        </div>
        <Rewards referralCount={referralCount}  numberOfUsersWhichDid25Referrals={numberOfUsersWhichDid25Referrals}/>
        <Sharing referralLink={referralLink}/>
      </div>
  )
}




