import React from 'react'

export const ContentDe = () => {

  return (
      <div>
            <h2>DATENSCHUTZERKLÄRUNG</h2>
            <h3>1. Allgemeine Informationen</h3>
            <p>Diese Datenschutzerklärung enthält Informationen für Nutzer von Smartlope (im Folgenden: „Dienst“, „Portal“) zu Aspekten der Erfassung, Verarbeitung und Speicherung personenbezogener Daten im Rahmen der Verwendung des Dienstes gemäß den Anforderungen des allgemein geltenden Rechts, einschließlich der Anforderungen aus der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Amtsblatt EU L aus dem Jahr 2016, Nr. 119, S. 1, im Folgenden „DSGVO“).</p>
            <p>Der Verantwortliche berücksichtigt in angemessener Weise den Schutz der personenbezogenen Daten und die Vertraulichkeit der ihm anvertrauten Informationen. Personenbezogene Daten sind Informationen über eine identifizierte oder identifizierbare Person. Der Verantwortliche verarbeitet die personenbezogenen Daten zu Zwecken, die in dieser Datenschutzerklärung und in den AGB des Dienstes genannt werden. Dabei können je nach dem Zweck verschiedene Arten der Erhebung sowie verschiedene rechtliche Grundlagen zur Verarbeitung, Verwendung, Offenlegung und Speicherfrist Anwendung finden. Es werden nur personenbezogene Daten gesammelt, die die Personen, die den Dienst aufsuchen und verwenden, freiwillig angeben.</p>
            <p>Der Verantwortliche für Ihre personenbezogenen Daten (Art. 4 Abs. 7 DSGVO) ist: Smartlope GmbH, Wittstocker Straße 8, 10553 Berlin, E-Mail: privacy@smartlope.com, (im Folgenden: „Verantwortlicher“).</p><br />

            <p>
        Der Verantwortliche befolgt die Grundsätze bezüglich der Vertraulichkeit der Daten, die gesammelt werden, während die Nutzer Smartlope (im Folgenden: „Dienst“) verwenden.</p>
        <ol>
          <li>Der Verantwortliche sammelt die Daten von Personen, die sich u.a. über das dazu vorgesehene Anmeldeformular auf der Seite: https://join.smartlope.com bei dem Dienst angemeldet haben.</li>
          <li>Indem der Nutzer den Dienst in Anspruch nimmt, akzeptiert er die Grundsätze dieser Datenschutzerklärung.</li>
          <li>Die personenbezogenen Daten der Nutzer der Seite, die die Nutzer bei der Anmeldung beim Dienst und in ihrem Profil angeben, werden zur ordnungsgemäßen Erbringung der Dienstleistungen durch den Dienst verarbeitet. Sie werden auch zu den Zwecken verarbeitet, die in den jeweiligen zugehörigen Formularen des Dienstes angegeben sind. Die rechtliche Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b, f DSGVO.</li>
          <li>Die personenbezogenen Daten der Nutzer des Dienstes werden nur für einen unerlässlichen Zeitraum gespeichert (zum Beispiel für den Zeitraum, in dem der Nutzer in einem Verhältnis zum Dienst steht), es sei denn, der Nutzer erhebt vorher Widerspruch gegen die Verarbeitung seiner personenbezogenen Daten zu diesem Zweck. Die Angabe der personenbezogenen Daten ist freiwillig, sie ist aber zur Erbringung der von dem Dienst angebotenen Dienstleistungen (darunter auch zur Versendung von Marketinginformationen) unerlässlich.</li>
        <li>Zu lediglich unerlässlichen Zwecken können die personenbezogenen Daten, die der Dienst speichert, weitergeleitet werden an:<br />
        a.	externe Organisationen, die die Anwendungen/Funktionalität sichern oder Datenverarbeitungs- oder IT-Dienstleistungen erbringen (zum Beispiel Anbieter von Informationstechnologie, cloudgestützter Software (engl. cloud software) sowie Rechtssubjekte, die Dienstleistungen im Bereich Identitätsmanagement, Hosting und Website-Verwaltung, Datenanalysen, Erstellung von Sicherheitskopien, Sicherheit usw. erbringen);<br />
        b.	externe Organisationen, die uns bei der Lieferung der Waren oder Informationen oder der Erbringung der Dienstleistungen behilflich sind;<br />
        c.	Behörden, die die Einhaltung der Rechtsvorschriften beaufsichtigen, Regulierungsbehörden und andere Regierungsbehörden oder dritte Personen, wenn die Übermittlung der Daten aufgrund geltender Rechtsvorschriften erforderlich ist (Art. 6 Pkt. 1 lit. c DSGVO).<br />
        Externe Organisationen, deren Dienstleistungen wir bei der Durchführung unserer geschäftlichen Transaktionen in Anspruch nehmen, handeln ausschließlich in unserem Auftrag und sind vertraglich zur Einhaltung der Vorschriften zum Schutz personenbezogener Daten gemäß Art. 28 DSGVO verpflichtet.</li>
        <li>Personen, deren personenbezogene Daten der Verantwortliche verarbeitet, sind berechtigt, die ihnen zustehenden Rechte schriftlich oder in elektronischer Form geltend zu machen. Dies erfolgt über das Kontaktformular, das der Verantwortliche auf der Seite https://join.smartlope.com zur Verfügung stellt. Die Antwort auf die gestellte Forderung wird unverzüglich und spätestens binnen einer Frist von einem Monat ab Empfang der Forderung erteilt.</li>
        <li>Der Nutzer hat das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob er seine personenbezogenen Daten verarbeitet (Art. 15 DSGVO). Ist dies der Fall, so hat er ein Recht auf Auskunft über diese personenbezogenen Daten und auf Information u.a. bezüglich der Verarbeitungszwecke und der Kategorien der Daten, die verarbeitet werden; der Empfänger, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden; der geplanten Dauer, für die die personenbezogenen Daten gespeichert werden; der ihm zustehenden Rechte, darunter:<br />
        a.	das Recht auf Berichtigung (Art. 16 DSGVO), Datenübertragbarkeit, die Erlangung einer Kopie (Art. 20 DSGVO), Löschung (Art. 17 DSGVO) bzw. Einschränkung der Verarbeitung der personenbezogenen Daten (Art. 18 DSGVO);<br />
        b.	das Recht, die Einwilligung zu widerrufen (Art. 7 Abs. 3 DSGVO);<br />
        c.	das Recht auf Widerspruch gegen die Datenverarbeitung (Art. 21 DSGVO);<br />
        d.	das Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO);<br />
          sowie Informationen über automatisierte Entscheidungsfindung, darunter Profiling, wesentliche Informationen über die Grundsätze der Entscheidungsfindung sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
        </ol>
        <br />
        <h3>2.	Cookie-Dateien und Profiling</h3>
        <ol>
          <li>Der Verantwortliche kann unter der Bedingung, dass der Nutzer der Seite sich damit einverstanden erklärt, mit Hilfe von Cookie-Dateien Informationen auf dem Computer des Nutzers speichern.</li>
          <li>Cookie-Dateien werden weder zur Erlangung jeglicher Informationen über die Nutzer des Dienstes noch zur Nachverfolgung ihrer Navigation verwendet. Die Cookie-Dateien, die auf der Seite Anwendung finden, speichern keine personenbezogenen Daten oder anderen Informationen, die von den Nutzern gesammelt werden. Sie werden unter anderem dazu verwendet, um sich einige Präferenzen des Nutzers zu merken und dem Verantwortlichen bei der Verbesserung der Seite behilflich zu sein.</li>
          <li>In vielen Fällen sind Programme zur Darstellung von Internetseiten (Webbrowser) darauf voreingestellt, die Speicherung von Cookie-Dateien auf dem Endgerät des Nutzers zuzulassen. Die Nutzer der Seite können jederzeit Änderungen an den Einstellungen bezüglich der Cookie-Dateien vornehmen. Diese Einstellungen können insbesondere dahingehend geändert werden, dass die automatische Bedienung von Cookie-Dateien in den Einstellungen des Webbrowsers blockiert wird oder jedes Mal über die Speicherung der Dateien auf dem Gerät des Seitennutzers informiert wird. Detaillierte Informationen zu den Möglichkeiten und den Arten der Bedienung von Cookie-Dateien sind in den Programmeinstellungen (des Webbrowsers) verfügbar.</li>
          <li>Der Nutzer kann seine Zustimmung, über die oben die Rede ist, über die Einstellungen des auf seinem Telekommunikationsgerät installierten Programms oder über die Einstellung des Dienstes zum Ausdruck bringen. Das heißt: um die Nutzung von Cookie-Dateien abzuschalten oder einzuschränken, kann der Nutzer die Einstellungen seines Webbrowsers dementsprechend abändern. Dies kann jedoch nach sich ziehen, dass der Dienst nicht ordnungsgemäß funktioniert oder der Zugang zu einigen Unterseiten der Seite verloren geht.</li>
          <li>Der Verantwortliche teilt mit, dass sich die Einschränkung der Anwendung von Cookie-Dateien auf einige Funktionalitäten auswirken kann, die auf den Webseiten des Dienstes verfügbar sind.</li>
        </ol>
        <br />
        <h3>3.	Nutzung des Dienstes und Bedrohungen</h3>
        <ol>
          <li>Den Nutzern des Dienstes ist bewusst, dass die Daten, die in einem öffentlichen Telekommunikationsnetz zwischen seinem Gerät und dem Dienst übermittelt werden, nicht völlig sicher sind. Der Verantwortliche ist nicht in der Lage, den vollen Schutz und die volle Sicherheit dieser Daten während der Kommunikation mit dem Dienst zu gewährleisten. Er gewährleistet jedoch, dass er entsprechende Maßnahmen trifft, um die Daten, die ihm auf elektronischem Weg übermittelt werden, insbesondere die personenbezogenen Daten, die der Nutzer über die elektronischen Formulare angibt, zu schützen.</li>
          <li>Der Zugang zur Datenbank ist durch ein Passwort geschützt und die Dateien werden auf per Encrypting File System verschlüsselten Servern aufbewahrt.</li>
          <li>Auf dem Portal können sich Links zu Seiten oder Diensten dritter Personen befinden. Diese Links dritter Personen können mit ihrer eigenen auf elektronischem Weg erbrachten Dienstleistungstätigkeit und ihren Datenschutzbestimmungen verbunden sein, zu deren Einhaltung sich der Nutzer verpflichtet, nachdem er auf den jeweiligen Link klickt und die Seite verlässt.</li>
          <li>Änderung der Person, die den Dienst verwaltet<br />
          Es können Umstände eintreten, in denen der Verantwortliche beschließt, die Gänze oder Teile seines Unternehmens oder seiner Aktiva zu verkaufen oder zu übertragen. In diesem Fall kann der Verantwortliche den dritten Personen die personenbezogenen Daten der Nutzer im Rahmen des geplanten Geschäfts übertragen oder zur Verfügung stellen. In solchen Situationen versichert der Verantwortliche, dass er die dritten Personen zur Sicherung eines adäquaten Schutzes der personenbezogenen Daten, die über die Seite gesammelt werden, verpflichtet. Zusätzlich informiert der Verantwortliche die Nutzer der Seite über derartige Umstände. Den Nutzern steht in diesem Fall das Recht zu, die Löschung ihrer Daten zu fordern.</li>
          <li><b>Änderungen der Datenschutzerklärung</b><br />
            Diese Datenschutzerklärung kann von Zeit zu Zeit Änderungen unterliegen. Jegliche Änderungen der Datenschutzerklärung werden auf der Website veröffentlicht.

          </li>
          <li><b>Kontakt</b><br />
            Bei Fragen zur Verarbeitung personenbezogener Daten durch den Verantwortlichen können Sie den Verantwortlichen unter der E-Mail-Adresse privacy@smartlope.com.
          </li>
        </ol>
      </div>
  )
}
