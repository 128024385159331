import React from 'react'
import {goToPage} from "../../routing/routing"
import {PAGE} from "../../routing/page"
import {translate} from "../../translation/translate"

export const ContentEn = () => {
  return (
      <div>
          <h2>Smartlope – Referral Programme Terms and Conditions</h2>
        <h3>§ 1. Introductory Provisions</h3>
        <p>1.	The following Terms and Conditions shall determine the conditions for participating in the Smartlope Referral Programme (“the Programme”), including conditions that must be met in order to receive the prizes provided for in the Programme. </p>
        <p>2.	Providing an email address to the Newsletter at https://join.smartlope.com and submitting it in the form is tantamount to accepting these Terms and Conditions as well as the Additional Provisions available on the Website.</p>
        <p>3.	Both the following Terms and Conditions and the scope of the Services may be subject to change and modification, in particular due to changes in the available functions and changes to legal regulations. Users shall be informed of any changes to the Terms and Conditions through their Account and by email. Further use of the Website means accepting any amendments to the Terms and Conditions. </p>
       <p>4.	The Website is operated and administered by Smartlope GmbH, Wittstockerstr. 8, 10553 Berlin, Germany, Amtsgericht Berlin-Charlottenburg HRB 216801 B. Users may contact the Administrator using the email address contact@smartlope.com.</p>
      <p>5.	The Website is available for persons who are at least 18 years of age. </p>
      <p>6.	The use of the Website is free of charge, except for functions designated as Paid Services. The Administrator may decide to introduce a fee for using the Website or additional paid features. In such a situation, Users shall have the ability to delete their Accounts without incurring any costs. </p>
        <br />
      <h3>§ 2. Definitions</h3>
      <p>1.	<b>Administrator / Smartlope</b> – Smartlope GmbH, Wittstockerstr. 8, 10553 Berlin, Amtsgericht Berlin-Charlottenburg HRB 216801 B.</p>
      <p>2.	<b>Mobile Application</b> – software meant to be installed and operated on mobile devices allowing the use of the Website. </p>
      <p>3.	<b>Data</b> – content, particularly information, photos and other multimedia as well as posts and messages that the User voluntarily and independently places on the Website, including Personal Data. </p>
      <p>4.	<b>Personal Data</b> – all information about the User constituting personal data as understood by Article 4(1) of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation), as amended, processed in accordance with and for the purposes of the aims set out in the Privacy Policy, in particular data provided voluntarily and independently by the User during the registration process as part of the registration form available at https://smartlope/pl/register or on the Mobile Application and when using the Website. </p>
      <p>5.	<b>Host</b> – the person (owner) managing the Site and the Event or Adventure organiser using the Smartlope Business website. </p>
      <p>6.	<b>Place</b> – a facility or location with a specific address and/or geographic coordinates for which a profile is created and maintained by Smartlope or by the Site Host via the Smartlope Business website.</p>
      <p>7.	<b>Additional Provisions</b> – documents that specifically regulate select situations related to </p>
      <p>7.1.	<a id="NL_privacyPrivacy_clicked" onClick={() => goToPage(PAGE.PRIVACY_POLICY)}><b>Privacy Policy</b></a></p>
      <p>7.2.	<a id="NL_privacyPrivacy_clicked" onClick={() => goToPage(PAGE.PERSONAL_DATA_CLAUSE)}><b>GDPR Regulations</b></a></p>
      <p>8.	<b>Adventure</b> – an organised meeting of a group of people, consisting in part or exclusively of Users, during which participants do a particular activity together (e.g. workshops).</p>
      <p>9.	<b>Terms and Conditions</b> – this document. </p>
        <p>10.	<b>Website</b> – the Smartlope website available at https://smartlope.com, used to establish and organise meetings with users using the Places and Events offers.</p>
        <p>11.<b>Services</b> – services provided by the Administrator electronically, i.e. on the Website. </p>
        <p>12.	<b>Paid Services</b> – Services which the User must purchase; some Paid Services can be unblocked by the User upon reaching the appropriate User Level. Detailed rules for making payments are specified in the Payment Regulations. </p>
        <p>13.<b>User</b> – a natural person using the Website through a User Account or by registering on the Website.</p>
          <p>14. <b>Event</b> – an organised meeting of a group of people, including Users, taking part in a group activity. Events can be organised using the infrastructure of the Place or separate to it.</p>
        <br />
        <h3>§ 3. Programme Eligibility Criteria </h3>

        <p>1.	A Programme participant must be a natural person and meet all of the following conditions:</p>
        <p>1.1.	is at least 18 years old and legally competent;</p>
        <p>1.2.	has provided an email address on https://join.smartlope.com/ and sent in the form, accepting these Terms and Conditions;</p>
        <p>1.3.	acts in accordance with these Terms and Conditions and the provisions of generally applicable law.</p>
        <p>2.	After submitting the form, the User shall receive a Unique Link (“Referral ID”), which shall be sent to the email address he/she provided. With the Referral ID, the User has the ability to invite others to register on the Website.</p>
        <p>3.	A person invited by the User should subscribe to the Newsletter and send his/her email address via the Unique Link (Referral ID) sent by the referring User.</p>
        <p>4.	Each individual may only have one account on the Website. Creating multiple accounts using several different email addresses is not permitted.</p>
        <br />
        <h3>§ 4. Prizes and Rules for Awarding Them</h3>
        <p>1.	After fulfilling the conditions stipulated in §3, the User has the chance to receive a prize according to the rules below.</p>
        <p>2.	If the User invites another person who effectively subscribes to Newsletter via the Unique Link (“Referral ID”) sent by the User, the User is eligible for the following prizes:</p>
        <p>2.1.	For 1 New User referred – good karma &#9786;</p>
        <p> 2.2. For 3 New Users referred – a EUR 10 voucher to be used on Smartlope within 6 months of the launch of the Smartlope Website;</p>
        <p>2.3.	For 7 New Users referred – an additional EUR 20 voucher to be used on Smartlope within 6 months of the launch of the Smartlope Website;</p>
        <p>2.4.	For 15 New Users referred – 5 additional vouchers worth EUR 20 each (total value: EUR 100) to be used on Smartlope within 6 months of the launch of the Smartlope Website</p>
        <br />
        <h3>§ 5. Final Provisions</h3>
        <p>1.	Matters not covered by these Terms and Conditions shall be governed by generally applicable law and other internal documents available on the Smartlope Website.</p>
        <p>2.	In the event that a User violates these Terms and Conditions, particularly in order to obtain a prize:</p>
        <p>2.1.	by using multiple email addresses;</p>
        <p>2.2.	by creating additional email addresses;</p>
        <p>he/she shall be disqualified from participating in the Programme and will not be entitled to any prizes.</p>
        <p>3.	These Terms and Conditions may be changed or modified.</p>
        <p>4.	These Terms and Conditions are available in full at https://join.smartlope.com/</p>
      </div>
  )
}
