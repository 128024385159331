import { PAGE } from "./page"

export const goToPage = (page = "") => {
  window.location.hash = page;
  window.scrollTo(0, 0);
}

export const isSignupSuccessPage = () => {
  return window.location.pathname.includes(PAGE.SIGNUP_SUCCESS)
}
