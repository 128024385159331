import React from 'react'
import { translate } from "../../translation/translate"
import './cookie_banner.css';

export const CookieBanner = ({
  cookieAccepted,
  setCookieAccepted
}) => {

  if (cookieAccepted) {
    return null
  }

  return (
    <div className="cookieBanner">
      <p>{translate("cookieAcceptance.label")}</p>
      <a id="NL_cookie_accepted" onClick={setCookieAccepted}>{translate("cookieAcceptance.cta")}</a>
    </div>
  );
}
