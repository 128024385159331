import React from 'react'
import { getPersistedLocale } from "../../translation/session"
import { ContentDe } from "./ContentDe"
import { ContentEn } from "./ContentEn"
import { GoBack } from "../../partials/navigation/GoBack"
import { Document } from "../../partials/document/Document"

export const ImprintPage = () => (
    <Document>
      <GoBack />
      {getPersistedLocale() === "de"
          ? <ContentDe/>
          : <ContentEn/>
      }
    </Document>
)

