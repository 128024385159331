import React from 'react'

export const ContentDe = () => {
  return (
      <div>
        <h3>Smartlope ist ein Service der:</h3>
        <br />
        Smartlope GmbH<br />
        Wittstockerstr. 8<br />
        10553 Berlin
        <br />
        <br />
        Email: <a href="mailto:contact@smartlope.com">contact@smartlope.com</a><br />
        HRB Nr: 216801 B, Amtsgericht Charlottenburg<br />
        Umsatzsteuer-Identifikationsnummer nach § 27 a Umsatzsteuergesetz: DE333177248<br />
        Geschäftsführer: Grzegorz Forysiak
        <br />
        <br />
        Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit. Diese ist zu erreichen unter <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>. Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen (§ 36 Abs. 1 Nr. 1 VSBG).
      </div>
  )
}
