import { Logo } from "../logo/Logo"
import React from "react"
import { LOCALE, setPersistedLocale } from "../../translation/session"
import enFlag from './en.svg'
import deFlag from './de.svg'
import { goToPage } from "../../routing/routing"
import { getCurrentActiveHomePage } from "../../App"

import './nav.css'

export const Nav = ({ referralId, referralLink, locale, setLocale, whiteBackground, isSplashDesktopPage }) => {

  const isEnglish = locale === LOCALE.en

  const toggleLanguage = () => {
    const targetLocale = locale === LOCALE.en
      ? LOCALE.de
      : LOCALE.en

    setPersistedLocale(targetLocale)
    setLocale(targetLocale)
  }

  return (
      <nav className={`navigation white_${whiteBackground}${isSplashDesktopPage ? ' splashDesktop': ''}`}>
        <Logo onClick={() => goToPage(getCurrentActiveHomePage(referralId, referralLink))}/>
        <div
          className="flag"
          id={`NL_country_flag_${isEnglish ? 'DE' : 'EN'}`}
          onClick={toggleLanguage}>
          <img
            alt="Change language"
            src={isEnglish ? deFlag : enFlag}
          />
        </div>
      </nav>
  )
}
